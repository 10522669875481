@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 320px) and (max-width: 576px) {
  .todo {
    width: 50%;
  }

  .title-bio {
    font-size: 16px;
  }
  .celd-bio2 {
    font-size: 9px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .grid {
    width: 20%;
    height: 20%;
  }
  .images-fav {
    /* max-width: 100%; */
    max-height: 80%;
    max-width: 80%;
  }
}

.header-image {
  width: 100%;
  height: 100%;
}

button {
  position: "absolute";
  top: 50;
  left: 50;
  border-radius: 50%;
}

.header {
  background-image: image("../src/images@/LifeIsAJourney4.jpg");
}

.nav-link {
  color: whitesmoke;
  margin-top: 20px;
  margin-bottom: 100px;
  text-align: left;
}

.brain,
.path {
  width: 50px;
}

.robot {
  background-color: #4da470;
}
.engranajes {
  background-color: #e36430;
}
.horse {
  background-color: #ffc857;
}
.butterfly {
  background-color: #ca4f3f;
}
.led {
  background-color: #4488cd;
}
.fav {
  background-color: #26562f;
  font-size: smaller;
}
.drum {
  background-color: #d43c3b;
}
.laptop {
  background-color: #54548c;
}

.bio {
  text-align: justify;
  font-family: "Lato", sans-serif;
  font-size: 18px;
}
/* .profile {
  margin-top: 200px;
} */

.videos {
  margin-top: 40px;
}

.videos,
.in-my-mind,
.my-path {
  display: block;
  margin: 50px auto 30px auto;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  text-align: center;
}

.celd-bio,
.celd-bio2 {
  background-color: #52fdfd2f;
  text-align: justify;
}

.title-bio {
  display: block;
  font-family: "Satisfy", cursive;
  color: #212529;
  font-size: 40px;
  text-align: center;
  padding: 15px;
  text-shadow: 1px 1px 2px #2b2b2b;
}

.bio {
  padding: 20px;
}

.HeaderEnd {
  padding-top: 20px;
  padding-right: 40px;
}

.quote {
  font-family: "Chivo", sans-serif;
  font-size: 28px;
  display: block;
}
.todo {
  width: 14rem;
  position: relative;
  text-align: center;
  width: 200px;
  height: 200px;
}
.images-fav {
  transition: transform 0.3s;
  height: 115%;
  width: auto;
  padding: 30px;
}
.float-text {
  color: #ffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-shadow: rgba(0, 0, 0, 0.603) 2px 2px;
}

.images-fav:hover {
  transform: scale(1.2);
}
.card {
  position: relative;
  margin-right: 5px;
}

.card-img-top {
  display: block;
  width: 50px;
}

.card:hover {
  transform: translateY(-15px);
  transition: transform 0.3s;
}

#coded,
#linkedin {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  display: block;
  color: #283c1c;
}

h5 {
  font-family: Lato sans-serif;
  font-weight: bold;
}

.text-over-img {
  color: #ffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-shadow: rgba(0, 0, 0, 0.603) 2px 2px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

